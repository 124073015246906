import React from "react";
import { AuthenticatingLottie } from "./AuthenticatingLottie";

const Fallback = () => {
  return (
    <div
      className="is-flex is-align-items-center is-justify-content-center"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="p-4">
        <AuthenticatingLottie text="We are loading applicaion for you!" />
      </div>
    </div>
  );
};

export default Fallback;
