import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import "bulma/bulma.sass";
import "./styles/main.scss";
import Fallback from "./components/extra/Fallback";

const App = lazy(() => import("./App"));

const Main = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default Main;
