export const uiRedux = {
  HeaderSize: "UI/HEADER-SIZE",
  FooterSize: "UI/FOOTER-SIZE",
  Width: "UI/WIDTH",
  Height: "UI/HEIGHT",
  HeaderActive: "UI/HEADER-ACTIVE",
  SiderActive: "UI/SIDER-ACTIVE",
  AppLoading: "UI/APP-LOADING",
  UserLoading: "UI/USER-LOADING",
};

export const apiRedux = {
  UserData: "API/USER-DATA",
};
