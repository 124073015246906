import React from "react";
import loadingLottie from "../../assets/loading-lottie.json";
import Lottie from "lottie-react";
import Title from "antd/es/typography/Title";

export const AuthenticatingLottie = ({ width = 100, text = "" }) => {
  return (
    <>
      <div className="is-flex is-justify-content-center">
        <Lottie
          animationData={loadingLottie}
          loop={true}
          style={{ width, maxWidth: "100%" }}
        />
      </div>
      {text && (
        <Title
          level={5}
          className="m-0 mt-2 has-text-centered has-text-weight-normal"
        >
          {text}
        </Title>
      )}
    </>
  );
};
