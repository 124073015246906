import { apiRedux } from "../config/redux";

const state = {
  UserData: null,
};

const apiReducer = (initialState = state, action) => {
  const { type, data } = action;

  switch (type) {
    case apiRedux.UserData:
      return { ...initialState, UserData: data };
    default:
      return initialState;
  }
};

export default apiReducer;
