import { uiRedux } from "../config/redux";

const state = {
  HeaderSize: 0,
  FooterSize: 0,
  Width: window.innerWidth,
  Height: window.innerHeight,
  HeaderActive: false,
  SiderActive: false,
  UserLoading: true,
  AppLoading: false,
};

const uiReducer = (initialState = state, action) => {
  const { type, data } = action;

  switch (type) {
    case uiRedux.HeaderSize:
      return { ...initialState, HeaderSize: data };
    case uiRedux.FooterSize:
      return { ...initialState, FooterSize: data };
    case uiRedux.Width:
      return { ...initialState, Width: data };
    case uiRedux.Height:
      return { ...initialState, Height: data };
    case uiRedux.HeaderActive:
      return { ...initialState, HeaderActive: data };
    case uiRedux.SiderActive:
      return { ...initialState, SiderActive: data };
    case uiRedux.UserLoading:
      return { ...initialState, UserLoading: data };
    case uiRedux.AppLoading:
      return { ...initialState, AppLoading: data };
    default:
      return initialState;
  }
};

export default uiReducer;
